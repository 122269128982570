<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newModuleGroup'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Module Group
            </h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12">
                <div class="group mb-3">
                  <label class="group-label">Basic Information</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Title</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Group Title"
                              v-model="formData.title"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Status</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              class="form-control"
                              v-model="formData.status"
                            >
                              <option value="1">Activate</option>
                              <option value="0">Deactivate</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formData: {
        id: "",
        title: "",
        status: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalMode",
    ]),
  },
  methods: {
    submit() {
      if (this.modalMode == "create") {
        this.$store.commit("setApiUrl", "api/module/group/store");
        this.$store.commit("addData", this.formData);
      } else if (this.modalMode == "edit") {
        this.$store.commit("setApiUrl", `api/module/group/${this.formData.id}`);
        this.$store.commit("updateData", this.formData);
      }
    },
    clearData() {
      this.formData.id = "";
      this.formData.title = "";
      this.formData.status = 1;
    },
  },
  watch: {
    dataId(value) {
      this.formData = this.dataLists.find(function (data) {
        return data.id == value;
      });
    },
    eventMessage(value) {
      if (value.indexOf("created success") >= 0) {
        this.clearData();
      }
    },
    modalMode(value) {
      if (value == "create") {
        this.clearData();
      }
    },
  },
};
</script>
