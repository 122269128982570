<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Module</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Module Group
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Module Group</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-sm-0 tx-right col-lg-7">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newModuleGroup', mode: 'create' })
          "
        >
          New Module Group
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select name="size">
            <option value="1000">1000</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" class="float-right" placeholder="Search..." />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-25p">Group title</th>
              <th class="wd-10p">Group Status</th>
              <th class="wd-10p text-right table-end-item">Action</th>
            </tr>
          </thead>
          <tbody v-for="(group, index) in dataLists" :key="index">
            <tr v-if="!removeList.includes(group.id)">
              <th scope="row" class="table-start-group">{{ ++index }}</th>
              <td>{{ group.title }}</td>
              <td>{{ group.status }}</td>
              <td class="text-right table-end-item">
                <a href="javascript:;" class="mr-2">
                  <i class="fas fa-receipt"></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-2"
                  @click="edit('newModuleGroup', group.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <!-- <a href="javascript:;" @click="drop(group.id)">
                  <i class="fa fa-trash"></i>
                </a> -->
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination />
        <Fields />
      </div>
    </div>
  </div>
</template>
<script>
import Fields from "./fields";
import { mapGetters } from "vuex";
import Pagination from "../../components/pagination/pagination";

export default {
  components: {
    Fields, Pagination
  },
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/module/group/${id}`);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.$store.commit("getData", `api/module/groups`);
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>